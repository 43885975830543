<template>
  <div>
      <div class="overview" v-if="!overviewLoading">
        <b-row>
          <b-col><statistic-card-horizontal icon="ShoppingCartIcon" :statistic="overview.today.sales_count" statistic-title="Sales today" /></b-col>
          <b-col><statistic-card-horizontal icon="ShoppingCartIcon" :statistic="formatCurrency(overview.today.total_commission)" statistic-title="Commission today" /></b-col>
          <b-col><statistic-card-horizontal icon="ShoppingCartIcon" color="success" :statistic="overview.this_week.sales_count" statistic-title="Sales this week" /></b-col>
          <b-col><statistic-card-horizontal icon="ShoppingCartIcon" color="success" :statistic="formatCurrency(overview.this_week.total_commission)" statistic-title="Commission this week" /></b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card
              body-class="pb-50"
            >
              <h6>Sales past week</h6>
              <h2>{{overview.past_week.count}}</h2>
              <!-- chart -->
              <vue-apex-charts
                height="245"
                :options="statisticsRequests.chartOptions"
                :series="statisticsRequests.data"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h2>Orders</h2>
            <b-card
              body-class="pb-50">
              <vue-good-table
                :columns="tableSettings.columns"
                :rows="overview.orders"
                :select-options="{
                  enabled: false,
                }"
                :pagination-options="{
                  enabled: false,
                }"
                class="table-valign-middle"                
              ></vue-good-table>
            </b-card>
          </b-col>
        </b-row>
      </div>
      
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary" label="Loading" v-show="overviewLoading"></b-spinner>
      </div>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner, BCard, } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { VueGoodTable } from 'vue-good-table'

import api from '@/api'

import '@/@core/scss/vue/libs/vue-good-table.scss'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BCard,
    StatisticCardHorizontal,
    VueApexCharts,
    VueGoodTable,
  },

  data() {
    return {
      overview: {},
      overviewLoading: true,

      tableSettings: {
        searchTerm: '',
        columns: [
          {
            label: '#',
            field: 'ID',
          },
          {
            label: 'Product number',
            field: 'productnumber',
          },
          {
            label: 'Channel',
            field: 'channel',
          },
          {
            label: 'Date',
            field: 'date',
          },
          {
            label: 'Commission',
            field: 'commission',
          },
        ],
        rows: [],
      },

      statisticsRequests: {
        data: [
          {
            name: 'Testgrafiek',
            data: [],
          },
        ],
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: 'rgba(0, 0, 0, .1)',
            strokeDashArray: 8,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },

  methods: {
    getStatsOverview() {
      this.overviewLoading = true

      api.stats.overview().then(response => {
        this.overview = response.data

        this.statisticsRequests.data = this.last7DaysData
        this.statisticsRequests.chartOptions.xaxis.categories = this.last7DaysLabels
        
        this.overviewLoading = false
      })
    },

    formatCurrency(value) {
      if (typeof value !== "number") {
          return value;
      }

      var formatter = new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR'
      });

      return formatter.format(value);
    }
  },

  computed: {
    last7DaysData() {
      return [
        {
          name: 'Sales',
          data: Object.values(this.overview.past_week.per_day),
        },
      ]
    },

    last7DaysLabels() {
      if (!this.overview.past_week.per_day) return []

      return Object.keys(this.overview.past_week.per_day)
    },
  },

  mounted() {
    this.getStatsOverview()
  },
}
</script>

<style>

</style>
